var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.tempCompetenceUnit)?[_c('b-table-simple',[_c('b-thead',[_c('b-th',{staticClass:"unit-competence-header"},[_c('div',{staticClass:"p-1 d-flex"},[_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.tempcompetenceunit", false, "Unidad de competencia" ).toUpperCase())+" N°"+_vm._s(_vm.tempCompetenceUnit.order)+": ")]),_c('div',{staticClass:"w-100"},[_c('SentenceContainer',{attrs:{"Sentence":_vm.tempCompetenceUnit}})],1),_c('div',[_c('span',{staticClass:"text-white secondary-color p-1 rounded",staticStyle:{"white-space":"nowrap"}},[_vm._v(" Ponderación: "+_vm._s(_vm.tempCompetenceUnit.weighing)+"% ")]),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.top",value:(
                  `Editar ${_vm.$getVisibleNames(
                    'teaching.tempcompetenceunit',
                    false,
                    'Unidad de competencia'
                  )}`
                ),expression:"\n                  `Editar ${$getVisibleNames(\n                    'teaching.tempcompetenceunit',\n                    false,\n                    'Unidad de competencia'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true,"top":true}}],staticClass:"ml-2",on:{"click":function($event){return _vm.$bvModal.show(
                    `edit-unit-competence-modal-${_vm.tempCompetenceUnit.id}`
                  )}}}):_vm._e(),_c('b-modal',{attrs:{"id":`edit-unit-competence-modal-${_vm.tempCompetenceUnit.id}`,"hide-footer":"","title":`Editar ${_vm.$getVisibleNames(
                  'teaching.tempcompetenceunit',
                  false,
                  'Unidad de competencia'
                )}`,"size":"lg"}},[(_vm.oldMatter)?_c('CompetenceUnitForm',{attrs:{"matter_id":_vm.oldMatter.id,"CompetenceUnit":_vm.tempCompetenceUnit,"show_delete_button":true},on:{"created":function($event){return _vm.$bvModal.hide(
                      `edit-unit-competence-modal-${_vm.tempCompetenceUnit.id}`
                    )},"updated":function($event){return _vm.$bvModal.hide(
                      `edit-unit-competence-modal-${_vm.tempCompetenceUnit.id}`
                    )}}}):_vm._e()],1)],1)])])],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_c('div',{staticClass:"d-flex flex-column"},[(_vm.oldMatter)?_c('draggable',_vm._b({staticClass:"list-group",attrs:{"disabled":!_vm.allows_crud,"group":"study_unit","handle":".handle"},model:{value:(_vm.studyUnitsList),callback:function ($$v) {_vm.studyUnitsList=$$v},expression:"studyUnitsList"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},[_vm._l((_vm.studyUnitsList),function(study_unit){return _c('UnitSection',{key:`unit-section-${study_unit.id}`,staticClass:"my-2",attrs:{"study_unit_id":study_unit.id,"allows_crud":_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed,"egress_profile_matter_id":_vm.egress_profile_matter_id,"temp_competence_unit":_vm.temp_competence_unit_id,"matter_id":_vm.oldMatter.id}})})],2)],1):_vm._e(),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('b-button',{staticClass:"secondary-button ml-auto mr-auto mb-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
                    `add-study-unit-modal-${_vm.temp_competence_unit_id}`
                  )}}},[_c('b-icon-plus'),_vm._v("Agregar "+_vm._s(_vm.$getVisibleNames("teaching.ramicro", true, "RA Micro"))+" ")],1):_vm._e()],1)])],1)],1)],1)]:[(_vm.oldMatter)?_c('div',[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"disabled":!_vm.allows_crud,"group":"study_unit","handle":".handle"},model:{value:(_vm.studyUnitsList),callback:function ($$v) {_vm.studyUnitsList=$$v},expression:"studyUnitsList"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},[_vm._l((_vm.studyUnitsList),function(study_unit){return _c('UnitSection',{key:`unit-section-${study_unit.id}`,staticClass:"my-2",attrs:{"study_unit_id":study_unit.id,"allows_crud":_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed,"egress_profile_matter_id":_vm.egress_profile_matter_id,"temp_competence_unit":_vm.temp_competence_unit_id,"matter_id":_vm.oldMatter.id}})})],2)],1),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('b-button',{staticClass:"secondary-button my-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`add-study-unit-modal-${_vm.temp_competence_unit_id}`)}}},[_c('b-icon-plus'),_vm._v("Agregar "+_vm._s(_vm.$getVisibleNames("teaching.ramicro", true, "RA Micro")))],1):_vm._e()],1):_vm._e()],_c('b-modal',{attrs:{"id":`add-study-unit-modal-${_vm.temp_competence_unit_id}`,"hide-footer":"","title":`Agregar ${_vm.$getVisibleNames(
      'teaching.ramicro',
      true,
      'RA Micro'
    )}`,"size":"lg"}},[(_vm.oldMatter)?_c('StudyUnitForm',{attrs:{"matter_id":_vm.oldMatter.id,"egress_profile_matter_id":_vm.egress_profile_matter_id,"temp_competence_unit":_vm.temp_competence_unit_id,"show_temp_competence_unit":true},on:{"created":function($event){return _vm.$bvModal.hide(`add-study-unit-modal-${_vm.temp_competence_unit_id}`)},"close_modal":function($event){return _vm.$bvModal.hide(`add-study-unit-modal-${_vm.temp_competence_unit_id}`)}}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }